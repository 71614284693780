<template>
  <div class="dialog-box">
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      :width="width"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :top="top"
    >
      <div class="contain"><slot></slot></div>
      <span slot="footer">
        <el-button @click="handleClose"> 取消</el-button>
        <el-button type="primary" @click="handleReqData"> 确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["title", "width", "top", "showDialog"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    handleReqData() {
      this.$emit("handleReqData");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  padding: 18px 12px 7px !important;
}
/deep/ .el-dialog__title {
  font-size: 14px !important;
  font-weight: bold !important;
  padding-left: 10px;
}
/deep/ .el-dialog__headerbtn {
  top: 15px !important;
}
/deep/ .el-dialog__body {
  border-top: 1px solid #c0c0c055;
  margin: 0 20px;
  line-height: 40px;
  padding: 30px 0 !important;
}
/deep/ .dialog-box .el-button {
  margin: 0 20px;
}
/deep/ .el-dialog .el-input__inner {
  border-radius: 0 !important;
}
/deep/ .el-dialog .el-form-item {
  margin-bottom: 0px !important;
}
</style>
